<template>
<div  id="sellerBox">
  <div class=" aiz-main-wrapper d-flex flex-column" :class="showMenu ? 'side-menu-open' : 'side-menu-closed'">
    <leftBar></leftBar>

    <div class="aiz-content-wrapper">

      <topRightBar></topRightBar>
      <div class="aiz-main-content">
        <router-view></router-view>
        <div class="bg-white text-center py-3 px-15px px-lg-25px mt-auto border-sm-top">
					<p class="mb-0">© 2023</p>
				</div>
      </div>
    </div>
   
  </div>
</div>
</template>
<script>
import { shopInfo  } from '@/api/seller'
import leftBar from '@/components/leftBar'
import topRightBar from '@/components/topRightBar'
import { getCurrentList } from '@/api/user';
export default {
  name: 'home',
  components: {
    leftBar,
    topRightBar
  },
  data() {
    return {
      transitionName: ''
    }
  },
  computed: {
    showMenu() {
      return this.$store.state.showMenu
    }
  },
  watch: {
    showMenu() {
      this.checkScroll()
    }
  },
  mounted() {
    this.init()
    this.initCurrency()
  },
  methods: {
    init() {
      shopInfo().then(res => {
        this.$store.state.shopInfo = res.data
      })
    },
    initCurrency() {
      getCurrentList().then(res => {
        if (res.code == 0) {
          let currentList = res.data.Items
          if (currentList.length > 0) {
            let symbol = localStorage.getItem('moneySymbol')
            let index = currentList.findIndex(v => {
              return v.Symbol == symbol
            })
            if (index === -1) {
              index = 0
            }
            this.$store.state.moneySymbol = currentList[index].Symbol
            this.$store.state.moneyRate = currentList[index].Rate
          }
        }
      })
    },
    checkScroll() {
      // let width = window.innerWidth
      // if (width < 768 && this.showMenu) {
      //   document.body.style.overflow = 'hidden'
      // } else {
      //   document.body.style.overflow = 'auto'
      // }
    }
  },
}
</script>