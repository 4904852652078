<template>
  <div class="aiz-sidebar-wrap" >
    <div class="aiz-sidebar left c-scrollbar">
      <div class="aiz-side-nav-logo-wrap">
        <div class="d-block text-center my-3">
          <img class="mw-100 mb-3 brand-icon" :src="logoUrl">
          <h3 class="fs-16  m-0 text-primary">{{shopInfo.Name}}</h3>
          <p class="text-primary">{{userInfo.Email}}</p>
        </div>
      </div>
      <div class="aiz-side-nav-wrap">
        <div class="px-20px mb-3">
          <input class="form-control bg-soft-secondary border-0 form-control-sm text-white" type="text" :placeholder="$t('zai-cai-dan-zhong-sou-suo')" >
        </div>
        <ul class="aiz-side-nav-list" id="search-menu" v-show="showSearchMenu">
        </ul>
        <ul class="aiz-side-nav-list" id="main-menu" data-toggle="aiz-side-menu" v-show="!showSearchMenu">
          <li class="aiz-side-nav-item mm-active" v-for="(menu, i) in menuData" :key="i">
            <a class="aiz-side-nav-link" :class="menu.name == routeName ? 'active' : ''"  @click="menuClick(menu, i)">
              <i class="las  aiz-side-nav-icon" :class="menu.icon"></i>
              <span class="aiz-side-nav-text">
                {{ menu.title }}
                <span v-if="menu.point && messageCount > 0" class="red-point"></span>
              </span>
              <span class="aiz-side-nav-arrow" v-show="menu.children"></span>
            </a>
            <ul class="aiz-side-nav-list level-2" v-if="menu.children" :class="menu.showSub ? 'mm-show' : ''" :style="`height: ${(menu.showSub ? menu.children.length : 0) * 40}px`">
              <li class="aiz-side-nav-item" v-for="(subMenu, index) in menu.children" :key="`child-${index}`">
                <a  class="aiz-side-nav-link " :class="subMenu.name == routeName ? 'active' : ''" @click.stop="menuClick(subMenu, i, index)">
                  <span class="aiz-side-nav-text">{{ subMenu.title }}</span>
                </a>
              </li>
            </ul>
          </li>

        </ul>
      </div>
    </div>
    <div class="aiz-sidebar-overlay" @click="hideMenu"></div>
</div>
</template>
<script>
import { unReadCount } from '@/api/seller'
export default {
  name: 'tabbar',
  props:{

  },
  data() {
    return {
      // logoUrl: require('../../assets/imgs/logo.png'),
      logoUrl1: require('../../assets/imgs/logo.png'),
      showSearchMenu: false,
      routeName: '',
      menuData: [],
    }
  },
  watch: {
    '$route'() {
      this.changeRoute()
    },
    language(val) {
      this.menuData = JSON.parse(JSON.stringify(this.menuList))
    }
  },
  computed: {
    userInfo() {
			return this.$store.state.userInfo
		},
    shopInfo() {
			return this.$store.state.shopInfo
		},
    logoUrl() {
      return this.$store.state.logo.length > 0
          ? "/upload/tmp/" + this.$store.state.logo
          : this.logoUrl1;
    },
    language() {
			return this.$store.state.language
		},
    messageCount() {
			return this.$store.state.messageCount
		},
    menuList() {
      return [
        {
          title: this.$t('shou-ye'),
          icon: 'la-home',
          name: 'sellerDashboard'
        },
        {
          title: this.$t('chan-pin'),
          icon: 'la-shopping-cart',
          name: '',
          children: [
            {
              title: this.$t('chan-pin'),
              icon: 'la-home',
              name: 'productList',
            },
            {
              title: this.$t('chan-pin-ping-lun'),
              icon: 'la-home',
              name: 'productReviews',
            },
          ]
        },
        {
          title: this.$t('chan-pin-cang-ku'),
          icon: 'la-store',
          name: 'productStorehouse'
        },
        {
          title: this.$t('ding-dan'),
          icon: 'la-store',
          name: 'sellerOrders'
        },
        {
          title: this.$t('dian-pu-deng-ji'),
          icon: 'la-shopping-cart',
          name: '',
          children: [
            {
              title: this.$t('deng-ji-lie-biao'),
              icon: 'la-home',
              name: 'sellerPackage',
            },
            {
              title: this.$t('li-shi-gou-mai'),
              icon: 'la-home',
              name: 'packagePayList',
            },
          ]
        },
        {
          title: this.$t('dian-pu-zhi-tong-che'),
          icon: 'la-shopping-cart',
          name: '',
          children: [
            {
              title: this.$t('dian-pu-zhi-tong-che-0'),
              icon: 'la-home',
              name: 'spreadPackage',
            },
            {
              title: this.$t('li-shi-gou-mai-0'),
              icon: 'la-home',
              name: 'spreadPackagePayList',
            },
          ]
        },
        {
          title: this.$t('san-ji-fen-xiao'),
          icon: 'la-user-tie',
          name: 'sellerAffiliate'
        },
        {
          title: this.$t('cai-wu-zhong-xin'),
          icon: 'la-money-bill-wave-alt ',
          name: 'financialCenter'
        },
        {
          title: this.$t('dui-hua'),
          icon: 'la-comment ',
          name: 'sellerConversations',
          point: true
        },
        {
          title: this.$t('dian-pu-she-zhi'),
          icon: 'la-cog',
          name: 'shopSetting'
        },
        {
          title: this.$t('shou-dao-tui-kuan-qing-qiu'),
          icon: 'la-backward',
          name: 'sellerRefund'
        },
        {
          title: this.$t('zhi-fu-li-shi'),
          icon: 'la-history',
          name: 'sellerPayments'
        },
        {
          title: this.$t('yong-jin-li-shi'),
          icon: 'la-file-alt',
          name: 'sellerCommission'
        },
        {
          title: this.$t('gong-dan-0'),
          icon: 'la-atom',
          name: 'sellerTicket'
        },
        {
          title: this.$t('jiao-yi-mi-ma-0'),
          icon: 'la-gavel',
          name: '',
          children: [
            {
              title: this.$t('she-zhi-jiao-yi-mi-ma'),
              icon: 'la-store',
              name: 'sellerPassword'
            },
          ]
        },
        {
          title: this.$t('shen-fen-ren-zheng'),
          icon: 'la-cog',
          name: 'sellerVerify'
        },
      ]
    }
  },
  mounted() {
    this.changeRoute()
    this.menuData = JSON.parse(JSON.stringify(this.menuList))
    this.init()
  },
  methods: {
    init() {
      unReadCount().then(res => {
        this.$store.state.messageCount = res.data
      })
    },
    changeRoute() {
      let meta = this.$route.meta
      if (meta && meta.parent) {
        this.routeName = meta.parent
      } else {
        this.routeName = this.$route.name
      }
    },
    menuClick(menu, i) {
      if (menu.children) {
        let isShow = !menu.showSub
        this.$set(this.menuData[i], 'showSub', isShow)
      } else {
        if (menu.name) {
          this.$router.push({
            name: menu.name
          })
        }
      }
    },
    hideMenu() {
      this.$store.state.showMenu = false
    }
  }
}
</script>